import React, { Suspense, useEffect, useState } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { Redirect } from 'react-router';
import Navbar from './container/NavBar';
// import Footer from './component/Footer';
import Loader from './component/Loader';
import { CmsDataWrapper } from 'context/cmsData';
import BookingHeader from 'component/BookingHeader';

const HomePage = React.lazy(() => import('./container/Home'));
const RoomDetailPage = React.lazy(() => import('./component/RoomDetail'));
const LocationPage = React.lazy(() => import('./container/Location'));
const ContactPage = React.lazy(() => import('./container/Contact'));
const PolicyPage = React.lazy(() => import('./container/Policy'));
// const RoomsPage = React.lazy(() => import('./container/Rooms'));
// const GroupContactForm = React.lazy(() => import('./component/ContactForm'));

function App({ cmsData }) {
  const { 'core-data': coreData } = cmsData;

  return (
    <Suspense fallback={<Loader></Loader>}>
      <div className="App">
        <Navbar CoreData={coreData} />
        <BookingHeader></BookingHeader>
        <Switch>
          <Route path="/rooms/:slug" component={() => <RoomDetailPage />} />
          <Route path="/location" component={() => <LocationPage />} />
          <Route path="/contact-us" component={() => <ContactPage />} />
          <Route path="/policies" component={() => <PolicyPage />} />
          <Route exact path="/" component={() => <HomePage />} />
          <Route path="*" render={() => { return <Redirect to="/" ></Redirect> }}></Route>
        </Switch>
        {/* <Footer cmsData={{ Policies, CoreData }} /> */}
      </div>
    </Suspense>
  );
}

export default CmsDataWrapper(App, ['core-data']);
