import React, { useState } from 'react';
import { IoMdMenu, IoMdClose } from 'react-icons/io';
import { Link, withRouter } from 'react-router-dom';

import './style.css';


function MobileNavbar({ CoreData }) {
  const { bookingEngineUrl } = CoreData;
  const [isOpen, setIsOpen] = useState(false);
  return (<div className="fixed z-20 text-yellow-700">

    <div className={`fixed mt-4 mr-4 top-0 right-0 cluster-l`}>
      {/* <a
        className='no-underline uppercase btn navbar-bg-color'
        target="_blank"
        rel="noopener noreferrer"
        href={bookingEngineUrl}>
        Book Online
      </a> */}
      <button className={`p-1 navbar-bg-color`} onClick={() => {
        setIsOpen(true);
      }}>
        <IoMdMenu className="text-4xl"></IoMdMenu>
      </button>
    </div>
    <div className={`text-2xl uppercase text-center navbar-bg-color stack-l mobile-nav__container ${isOpen ? 'visible' : ''}`} style={{ '--justify': 'center' }}>
      <button className='absolute top-0 right-0'>
        <IoMdClose className="icon m-4" onClick={() => {
          setIsOpen(false);
        }} />
      </button>
      <Link to="/" onClick={() => {
        setIsOpen(false);
      }}>
        Home
      </Link>
      <Link to="/location" onClick={() => {
        setIsOpen(false);
      }}>
        Location
      </Link>
      <Link to="/policies" onClick={() => {
        setIsOpen(false);
      }}>
        Policies
      </Link>
      <Link to="/contact-us" onClick={() => {
        setIsOpen(false);
      }}>
        Contact Us
      </Link>
    </div>
  </div>
  );
}

const NavBar = ({ CoreData }) => {
  return (
    <MobileNavbar CoreData={CoreData} />
  );
}

export default withRouter(NavBar);