import React, { useState } from 'react';
import { DatePicker, Space } from 'antd';
import '../BookingHeader/style.css';



const BookingHeader = () => {
    const [startDate,setStartDate] = useState('');
    const [endDate,setEndDate] = useState('');
    const [adults,setAdults] = useState(2);
    const [childrens,setChildrens] = useState(0);
    const onStartChange = (date, dateString) => {
        var startDate = date.format('YYYY-MM-DD');
        setStartDate(startDate);        
    };
    const onEndChange = (date, dateString) => {
        var endDate = date.format('YYYY-MM-DD');
        setEndDate(endDate);
    };
    const onChange = (e) =>{
        if(e.target.name == 'adults'){
            setAdults(e.target.value);
        }else if(e.target.name == 'childrens'){
            setChildrens(e.target.value);
        }
    }
    const OpenLink = () =>{
        if(startDate != '' && endDate != ''){
            var link = `https://stmauricebeachinn.client.innroad.com/#search={"checkIn":"${startDate}","checkOut":"${endDate}","guests":[{"adults":${adults},"children":${childrens}}],"propertyId":2820}`;
            window.open(link,'_blank');
        }
    }
    return (
        <div className='w-full h-11 absolute top-4 z-10 '>
            <div className='justify-evenly border-x border-gray-800 w-112 text-center bg-sky-900 text-white px-5 mx-auto grid grid-cols-5 gap-0 md:w-160  lg:w-160 xl:w-160 '>
                <div className='w-28 h-11 border-r border-gray-800'>
                    <label className='w-28 text-xs h-5 block'>CheckIn</label>
                    <DatePicker className='w-24 h-6 p-0 border-0 bg-sky-900 text-white' format='DD MMM, YYYY' onChange={onStartChange} />
                </div>
                <div className='w-28 h-11 border-r border-gray-800'>
                    <label className='w-28 text-xs h-5 block'>CheckOut</label>
                    <DatePicker className='w-24 h-6 p-0 border-0 bg-sky-900' format='DD MMM, YYYY' onChange={onEndChange} />
                </div>
                <div className='w-28 h-11 border-r border-gray-800'>
                    <label className='w-28 text-xs h-5 block'>Adults</label>
                    <input className='w-28 text-center border-r border-gray-800 bg-sky-900' type='number' name="adults" value={adults} onChange={onChange} ></input>
                </div>
                <div className='w-28 h-11 border-r border-gray-800'>
                    <label className='w-28 text-xs h-5 block'>Childrens</label>
                    <input className='w-28 text-center border-r border-gray-800 bg-sky-900' type='number' name='childrens' value={childrens} onChange={onChange} ></input></div>
                <div className='w-28 h-11 bg-sky-900 text-white'>
                    <button className='w-28 h-11' onClick={OpenLink}>Search</button></div>
            </div>
        </div>
    );
}

export default BookingHeader;